/* eslint-disable react/no-array-index-key */
import { Image } from 'tt-ui-kit';
import { useNavigate } from 'react-router';

import { SOCIAL_NETWORKS } from 'constants/menuPage';
import styles from './MenuPageFooter.module.scss';

const MenuPageFooter = () => {
  const navigate = useNavigate();

  const goToMenu = () => {
    navigate('/sdgs');
  };

  return (
    <footer className={styles.menuFooterWrapper}>
      <div className={styles.menuFooterSecondWrapper}>
        {/* <div className={styles.menuFooterFirstLayer}>
          <div className={styles.menuFooterHome}>
            <Link href="/" passHref>
              <a>
                <div className={styles.menuFooterHomeIcon} />
                <span className={styles.menuFooterHomeTitle}>HOME</span>
              </a>
            </Link>
          </div>

          <nav className={styles.menuFooterNavigation}>
            <div className={styles.menuFooterHomeNav}>
              <Link href="/" passHref>
                <a>
                  <div className={styles.menuFooterHomeIcon} />
                  <span className={styles.menuFooterHomeTitle}>HOME</span>
                </a>
              </Link>
            </div>
            {FOOTER_NAV_ITEMS.map((navItem, index) => (
              <div className={styles.menuFooterNavigationItem}
              key={navItem.label + index}>
                <a>
                  <Link href={navItem.url}>{navItem.label}</Link>
                </a>
              </div>
            ))}
          </nav>

          <div className={styles.menuFooterButtons}>
            {FOOTER_BUTTONS.map((btn, index) => (
              <div className={styles.menuFooterButton} key={btn.label + index}>
                <a>
                  <Link href={btn.url}>{btn.label}</Link>
                </a>
              </div>
            ))}
          </div>
        </div> */}

        <div className={styles.menuFooterSecondLayer}>
          <div className={styles.menuFooterSecondLayerFirstBlock}>
            <div className={styles.menuFooterDesignedBy}>
              <b className={styles.menuFooterDesignedByTitle}>Designed by: </b>
              FCE GROUP AG
              <br />
              Dammstrasse 16, 6300 Zug, Switzerland
            </div>

            <div className={styles.menuFooterCertification}>
              <div className={styles.menuFooterCertificate}>
                <a
                  href="https://www.thegreenwebfoundation.org/green-web-check/?url=transparenterra.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    loader={() =>
                      'https://api.thegreenwebfoundation.org/greencheckimage/transparenterra.com?nocache=true'
                    }
                    src="https://api.thegreenwebfoundation.org/greencheckimage/transparenterra.com?nocache=true"
                    alt="This website is hosted Green - checked by thegreenwebfoundation.org"
                    layout="fill"
                  />
                </a>
              </div>
              <div className={styles.menuFooterMulticoloredCircle} onClick={goToMenu} aria-hidden>
                <div className={styles.menuFooterMulticoloredCircleIcon} />
              </div>
            </div>
            <div className={styles.menuFooterSecondLayerSocialMedia}>
              <div className={styles.menuFooterSocialMedia}>
                {SOCIAL_NETWORKS.map((net, index) => (
                  <a href={net.href} title={net.title} key={net.title + index}>
                    <div
                      className={styles.menuFooterSocialMediaIcon}
                      style={{ backgroundImage: `url(${net.iconSrc})` }}
                    />
                  </a>
                ))}
              </div>

              <div className={styles.menuFooterCopyright}>
                Copyright © {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.menuFooterSecondLayerSecondBlock}>
        <div className={styles.menuFooterSocialMedia}>
          {SOCIAL_NETWORKS.map(({ href, title, Icon, iconSrc }, index) => (
            <a href={href} title={title} key={title + index}>
              {Icon ? (
                <Icon className={styles.menuFooterSocialMediaIcon} />
              ) : (
                <div
                  className={styles.menuFooterSocialMediaIcon}
                  style={{ backgroundImage: `url(${iconSrc})` }}
                />
              )}
            </a>
          ))}
        </div>

        <div className={styles.menuFooterCopyright}>Copyright © {new Date().getFullYear()}</div>
      </div>
    </footer>
  );
};

export default MenuPageFooter;
