import { useNavigate } from 'react-router-dom';
import VideoBg from '../../../components/layout/VideoBg';
import Header from '../../../components/layout/Header';
import styles from './HomePage.module.scss';
import { useWindowSize } from '../../../utils/useWindowSize';

const MAX_MOBILE_WIDTH = 678;

const HomePage = () => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  return (
    <div className={styles.homePage}>
      <Header homePage />
      <VideoBg
        src="/video/start-bg.mp4"
        srcImg="/images/start-body-bg.jpg"
        isAutoPlay={windowSize.width > MAX_MOBILE_WIDTH}
      />
      <div className={styles.mainWrapper}>
        <div className={styles.startBl}>
          <h1>
            <div className={styles.startHeader}>
              TransparenTerra:
              <br /> No Sustainability
              <br /> Without Transparency
            </div>
            <div className={styles.startSubHeader}>
              An open data platform powered by AI and blockchain,
              <br /> designed for sustainability compliance.
            </div>
          </h1>
          <div className={styles.startTxt}>
            Harness cutting-edge technologies for transparent and trustworthy
            <br /> sustainability assessment, scoring, and reporting.
          </div>
          <div className={styles.startBut}>
            <button
              type="button"
              className={styles.startBtn}
              onClick={() => navigate(`/dashboard/feed`)}
            >
              START
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
